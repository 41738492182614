.MuiButton-outlinedPrimary{
    color: black !important;
}

.MuiButton-outlinedPrimary:hover{
    color: white !important;
}

.MuiFab-primary {
    background-color: @buttonHoverBackground !important;
  }

.MuiButton-root:hover {
    color: white;
    background: @buttonHoverBackground !important;
}

.MuiInputLabel-root{
    background-color: white;
}

.MuiCircularProgress-colorPrimary{
    color: @buttonHoverBackground !important;;
}

.Mui-checked{
    color: @buttonHoverBackground !important;
}

.MuiTextField-root{
    width: 100%;
}

.MuiFormHelperText-root{
    margin-top: 0;
    margin-left: 0;
}

.material-icons {
    font-size: 1.2em;
    margin-right: .5em;
}

.MuiPaper-root{
    padding: 1em;
    margin-top: 1em;
}