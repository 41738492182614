#assessmentForm {
    width: 100%; 
    
    .highlighted-instructions{
        font-size: 1.2em;   
        color: @importantText;     
    }

    .timeStamp{
        font-weight: bold;
        font-size: 1.2em;
        margin-bottom: 1em;
    }

    .reviewRequestAction{
        font-weight: bold;
        display: inline-block;
    }

    .reviewMessage{
        margin-bottom: 1em;
    }

    .flex-row{
        justify-content: space-between;

        .flex-col{
            margin: .5em 1em .5em 0;
        }
        .flex-col:last-child{
            margin: .5em 0 .5em 0;
        }
    }

    .flex-col{
        flex-grow: 1;
        min-width: 10em;
    
        @media (max-width : 600px) {
            flex-grow: 0;
        }
    }

    .stop{
        margin-bottom: 1em;
        font-weight: bold;
    }

    .check-box-with-help{
        display: inline;
    }

    .radio-help{
        margin-left: -1.5em;
        margin-right: 1.5em;
    }

    .help-section{
        flex-grow: 0;
        min-width: 2em;
    }

    .fa-envelope {
        margin-right: 1em;
    }

    .choice-group{
        justify-content: flex-start;
    }

    .choice-column{
        flex: 0 1 30%;
    }
}